<!--
 * @Author: 蒋威
 * @Date: 2022-04-21 11:15:48
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-17 09:38:14
 * @Description: 
-->
<template>
  <div class="wscn-http404-container response">
    <div class="wscn-http404">
      <img
        class="pic-404__parent"
        src="@/assets/404_images/404.png"
        alt="404"
      />
    </div>
    <div class="gohome-wrap">
      <a class="btn pointer" @click="gohome">返回首页</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageErr',
  methods: {
    gohome() {
      this.$router.push({
        name: 'home',
        params: {
          type: '',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wscn-http404-container {
  height: 100%;
  padding-top: 60px;
  .wscn-http404 {
    text-align: center;
    img {
      object-fit: cover;
      width: 100%;
      // height: 60vw;
    }
  }
  .gohome-wrap {
    text-align: center;
    margin-top: 20px;
    .btn {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      border-radius: 20px;
      color: #fff;
      // background-image: linear-gradient(to right, red, yellow);
      background-color: #51a3ef;
    }
  }
}
</style>
